import { loanStatuses } from '@/const'
import { createActionHelpers } from 'vuex-loading'
import api from '@/api'
import unionBy from 'lodash/unionBy'
import orderBy from 'lodash/orderBy'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { moneyFilter } from '@/filters'

const { startLoading } = createActionHelpers({ moduleName: 'loading' })

export default {
  state: {
    loans: [],
    loansPages: 0,
    loansTotal: 0,
    loan: null,
    systemCustomers: [],
    systemCustomer: null,
    loanProduct: {},
    loanCustomer: {},
    loanCustomerAccounts: [],
    loanCustomerRelations: null,
    loanAssets: [],
    loanMortgages: [],
    loanTransactions: [],
    loanTransactionsPages: 0,
    messageTemplate: null,
    loanResources: [],
    loanResourcesPages: 0,
    systemData: null,
    loanRelations: [],
    loanComments: [],
    commentsPages: 0,
    loanMessages: [],
    loanMessagesPages: 0,
    loanPaymentRelations: [],
    loanSchedule: null,
    loanSchedules: [],
    loanActiveSchedule: [],
    schedulePages: 0,
    schedulesTotal: 0,
    loanCustomerHistory: null,
    loanFinancial: null,
    loanInterests: [],
    loanTermDetails: null,
    customFieldsValues: [],
    loanIfrs: []
  },

  getters: {
    sortedComments: state => orderBy(state.loanComments, ['commentableType.id', 'commentableId', 'id'], ['desc', 'asc', 'desc']),
    loanStatusColor: () => loanStatus => {
      const colorMap = {
        [loanStatuses.inserted]: 'primary',
        [loanStatuses.signed]: 'primary',
        [loanStatuses.active]: 'success',
        [loanStatuses.delayed]: 'danger',
        [loanStatuses.collector]: 'danger',
        [loanStatuses.bankrupt]: 'danger',
        [loanStatuses.bailiff]: 'danger',
        [loanStatuses.conkedOut]: 'danger',
        [loanStatuses.terminated]: 'danger',
        [loanStatuses.breached]: 'danger',
        [loanStatuses.pending]: 'pending',
        [loanStatuses.deleted]: 'secondary'
      }
      return colorMap[loanStatus] || 'dark'
    },
    loanInvoiceStatusColor: () => invoiceStatus => {
      const colorMap = {
        INSERTED: 'primary',
        REQUESTED: 'primary',
        PENDING: 'primary',
        PAID_ON_TIME: 'success',
        PAID_LATE: 'warning',
        IN_DEBT: 'danger',
        PROCESSED: 'success'
      }
      return colorMap[invoiceStatus.id] || 'dark'
    },
    systemCustomersForSelect: (state, getters, rootState, rootGetters) => state.systemCustomers
      .map(customer => ({
        value: customer.id,
        text: `${rootGetters['customers/customerName'](customer)} (${customer.idCode})`,
        sectorId: customer.sectorId,
        segmentId: customer.segmentId,
        email: customer.email,
        phone: customer.phoneNumber
      })),
    optionsFromCustomerAccounts: (state, getters, rootState, rootGetters) => {
      const prepaymentTypeId = rootGetters['classifiers/classifierByName']('accountTypes', 'PREPAYMENT').id
      return [...state.loanCustomerAccounts, state.loanTermDetails?.account]
        .filter(account => account?.accountTypeId === prepaymentTypeId)
        .map(account => {
          const accountEntry = account.accountEntries.find(entry => entry.currencyId === rootGetters['classifiers/classifierByName']('currencies', rootState.settings.settings.currency.toUpperCase())?.id) || {}
          return {
            value: account.id,
            text: `${account.referenceNumber} (${moneyFilter(accountEntry.availableBalance)})`
          }
        })
    },
    optionsFromLoanAssets: (state, getters, rootState, rootGetters) => map(
      state.loanAssets.filter(asset => /IMMOVABLE/i.test(rootGetters['classifiers/classifierById']('assetTypes', asset.assetTypeId).name)),
      asset => { return { value: asset.id, text: asset.registerNumber } }
    ),
    optionsFromLoanAssetsWithId: (state, getters, rootState, rootGetters) => map(
      state.loanAssets.filter(asset => /IMMOVABLE/i.test(rootGetters['classifiers/classifierById']('assetTypes', asset.assetTypeId).name)),
      asset => { return { value: asset.id, text: asset.registerNumber ? `${asset.id} (${asset.registerNumber})` : asset.id } }
    ),
    scheduleById: state => id => state.loanSchedules.find(schedule => schedule.id === id) || {},
    activeSchedule: ({ loanSchedules }) => loanSchedules.find(({ status }) => /active/i.test(status.code)),
    loanProductFees: ({ loanProduct }) => loanProduct.fees?.reduce((res, fee) => ({
      ...res,
      [fee.feeType.code]: fee
    }), {})
  },

  actions: {
    async loadCustomerRelations ({ commit, dispatch, state }, { customerId = state.loanCustomer.id } = {}) {
      const { data: relations } = await startLoading(dispatch, 'customer:relations:fetch', () => api.getCustomerRelations({ customerId }))

      commit('SET_CUSTOMER_RELATIONS', { relations })
    },
    async findSystemCustomers ({ commit, dispatch }, { query, sectorId }) {
      if (!query) {
        commit('SET_SYSTEM_CUSTOMERS', { customers: [] })
        return Promise.resolve()
      }
      const response = await startLoading(dispatch, 'loans:systemCustomers:fetch', () => api.getCustomers({ filters: { criteria: query, sectorId } }))
      commit('SET_SYSTEM_CUSTOMERS', { customers: response.data.content })
    },

    async loadLoans ({ commit, dispatch }, { filters } = {}) {
      commit('SET_LOANS', { loans: [], loansPages: 0, totalElements: 0 })
      const { data: { content, totalPages, totalElements } } = await startLoading(dispatch, 'loans:fetch', () => api.getLoans({ filters }))
      commit('SET_LOANS', { loans: content, loansPages: totalPages, totalElements })
    },

    async loadLoan ({ commit, dispatch }, { id }) {
      commit('CLEAR_LOAN')
      const response = await startLoading(dispatch, 'loan:fetch', () => api.getLoanOverview({ id }))
      commit('SET_LOAN', { loan: response.data })
      dispatch('loadProduct')
    },

    async loadLoanFull ({ commit, dispatch }, { id }) {
      const response = await startLoading(dispatch, 'loan:full:fetch', () => api.getLoanFull({ id }))
      commit('SET_LOAN', { loan: response.data })
    },

    async loadLoanFinancial ({ commit, dispatch, state }, { id = state.loan.id } = {}) {
      const { data: financial } = await startLoading(dispatch, 'loan:financial:fetch', () => api.getLoanFinancial({ id }))
      commit('SET_LOAN_FINANCIAL', { financial })
    },

    async loadLoanIfrs ({ commit, dispatch, state }, { id = state.loan.id } = {}) {
      const { data: ifrs } = await startLoading(dispatch, 'loan:ifrs:fetch', () => api.getLoanIfrs({ id }))
      commit('SET_LOAN_IFRS', { ifrs })
    },

    async loadProduct ({ commit, dispatch, state }, { id } = { id: state.loan.productId }) {
      const response = await startLoading(dispatch, 'loan:product:fetch', () => api.getProduct({ id }))
      commit('SET_LOAN_PRODUCT', { product: response.data })
    },

    async createLoan ({ commit, dispatch }, { loanData }) {
      const response = await startLoading(dispatch, 'loan:save', () => api.postLoan({ loanData }))
      commit('SET_LOAN', { loan: response.data })
    },

    async createOverdraft ({ commit, dispatch }, { loanData }) {
      const { data: loan } = await startLoading(dispatch, 'loan:save', () => api.postOverdraft({ overdraftData: loanData }))
      commit('SET_LOAN', { loan })
    },

    async loadLoanCustomer ({ state, commit, dispatch }, { id = state.loan?.customerBorrower?.id } = {}) {
      const response = await startLoading(dispatch, 'loan:customer:fetch', () => {
        return api.getCustomer({ id })
      })
      commit('SET_LOAN_CUSTOMER', { customer: response.data })
    },

    async updateLoan ({ commit, dispatch }, { id, loanData }) {
      try {
        const { data: loan } = await startLoading(dispatch, `loan:${id}:save`, () => api.putLoan({ id, loanData }))
        commit('SET_LOAN', { loan })
        dispatch('loadLoanSchedule')
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },

    async updateLoanTerms ({ commit, dispatch }, { id, loanData }) {
      try {
        const { data: loan } = await startLoading(dispatch, `loan:${id}:save`, () => api.patchLoan({ id, loanData }))
        commit('SET_LOAN', { loan })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },

    async updateLoanTermDetails ({ commit, dispatch }, { contractId, termsData }) {
      try {
        const { data: termDetails } = await startLoading(dispatch, 'loan:term:details:save', () => api.postCreditContractTerms({ contractId, termsData }))

        commit('SET_LOAN_TERM_DETAILS', { termDetails })
        dispatch('loadLoan', { id: contractId })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },

    async updateOverdraft ({ commit, dispatch }, { id, overdraftData }) {
      try {
        const { data: loan } = await startLoading(dispatch, `loan:${id}:save`, () => api.patchOverdraft({ id, overdraftData }))
        commit('SET_LOAN', { loan })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },

    async createCustomerAccountFromLoanTerms ({ state, commit, dispatch }, { accountData, customerId }) {
      const { data: account } = await startLoading(dispatch, 'customer:account:save', () => api.postCustomerAccount({ customerId, accountData }))

      commit('SET_LOAN_CUSTOMER_ACCOUNT', { account })
    },

    async loadLoanCustomerAccounts ({ state, dispatch, commit }, { productGroup, customerId = state.loan.customerBorrower.id } = {}) {
      const response = await startLoading(dispatch, 'loan:customer:accounts:fetch', () => {
        return api.getCustomerAccounts({ customerId, productGroup })
      })
      commit('SET_LOAN_CUSTOMER_ACCOUNTS', { accounts: response.data })
    },

    async loadAssets ({ state, dispatch, commit }) {
      const response = await startLoading(dispatch, 'loans:assets:fetch', () => api.getLoanAssets({ id: state.loan.id }))

      commit('SET_LOAN_ASSETS', { assets: response.data })
    },

    async createAsset ({ state, dispatch, commit }, { assetData }) {
      const response = await startLoading(dispatch, 'loans:asset:save', () => api.postLoanAsset({ id: state.loan.id, assetData }))

      commit('SET_LOAN_ASSETS', { assets: response.data })
    },

    async updateAsset ({ state, dispatch, commit }, { id, assetData }) {
      const response = await startLoading(dispatch, `loans:asset:${id}:save`, () => api.putAsset({ id, assetData }))

      commit('UPDATE_LOAN_ASSET', { asset: response.data })
    },

    async removeAsset ({ state, dispatch, commit }, { id }) {
      await startLoading(dispatch, `loans:asset:${id}:remove`, () => api.deleteAsset({ id }))

      commit('REMOVE_LOAN_ASSET', { id })
    },

    async getAssetsMessages ({ state, dispatch, commit }, { id, lang }) {
      const { data } = await startLoading(dispatch, `loans:asset:${id}:messages`, () => api.getAssetsMessages({ id, lang }))

      return data
    },

    async loadLoanMortgages ({ state, dispatch, commit }) {
      const response = await startLoading(dispatch, 'loan:mortgages:fetch', () => api.getLoanMortgages({ id: state.loan.id }))

      commit('SET_LOAN_MORTGAGES', { mortgages: response.data })
    },

    async createLoanMortgage ({ state, dispatch, commit }, { mortgageData }) {
      const response = await startLoading(dispatch, 'loan:mortgage:save', () => api.postLoanMortgage({ id: state.loan.id, mortgageData }))

      commit('SET_LOAN_MORTGAGES', { mortgages: response.data })
    },

    async updateLoanMortgage ({ state, dispatch, commit }, { id, mortgageData }) {
      const response = await startLoading(dispatch, `loan:mortgage:${id}:save`, () => api.putMortgage({ id, mortgageData }))

      commit('SET_LOAN_MORTGAGES', { mortgages: response.data })
    },

    async removeLoanMortgage ({ state, dispatch, commit }, { id }) {
      await startLoading(dispatch, `loan:mortgage:${id}:remove`, () => api.deleteMortgage({ id }))

      commit('REMOVE_LOAN_MORTGAGE', { id })
    },

    async assignAssetToMortgage ({ state, dispatch, commit }, { id, assetData }) {
      const response = await startLoading(dispatch, `loan:mortgage:${id}:asset:save`, () => api.postMortgageAsset({ id, assetData }))

      commit('SET_LOAN_MORTGAGES', { mortgages: response.data })
    },

    async unAssignAssetFromMortgage ({ state, dispatch, commit }, { relationId }) {
      const response = await startLoading(dispatch, `loan:mortgage:asset:${relationId}:remove`, () => api.deleteMortgageAsset({ relationId }))

      commit('UPDATE_LOAN_MORTGAGE', { mortgage: response.data })
    },

    async updateLoanStatus ({ state, dispatch, commit }, { statusId, periodStart }) {
      const response = await startLoading(dispatch, 'loan:status:save', () => api.putLoanStatus({ id: state.loan.id, statusId, periodStart }))

      commit('SET_LOAN', { loan: response.data })
      dispatch('loadLoanSchedule')
      dispatch('loadLoanSchedules')
    },

    async loadLoanSchedule ({ commit, dispatch, state }, { scheduleId = state.loan.schedule } = {}) {
      const { data: schedule } = await startLoading(dispatch, 'loan:schedule:fetch', () => api.getLoanScheduleById({ loanId: state.loan.id, scheduleId }))

      commit('SET_LOAN_SCHEDULE', { schedule })
    },

    async loadLoanSchedules ({ commit, dispatch, state }, { filters } = {}) {
      commit('SET_LOAN_SCHEDULES', { schedules: [], schedulePages: 0, totalElements: 0 })
      const { data: { content, totalPages, totalElements } } = await startLoading(dispatch, 'loan:schedules:fetch', () => api.getLoanSchedules({ loanId: state.loan.id, filters }))
      commit('SET_LOAN_SCHEDULES', { schedules: content, schedulePages: totalPages, totalElements })
    },
    async loadLoanActiveSchedule ({ commit, dispatch, state } = {}) {
      commit('SET_LOAN_ACTIVE_SCHEDULE', { schedules: [] })
      const { data: { content } } = await startLoading(dispatch, 'loan:schedules:fetch', () => api.getLoanActiveSchedule({ loanId: state.loan.id }))
      commit('SET_LOAN_ACTIVE_SCHEDULE', { schedules: content })
    },

    async updateLoanSchedule ({ state, dispatch, commit }, { id, data }) {
      try {
        const { data: schedule } = await startLoading(dispatch, 'loan:schedule:save', () => api.postLoanSchedule({ id, data }))
        commit('SET_LOAN_SCHEDULE', { schedule })
        dispatch('loadLoanSchedules')
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },

    async terminateLoan ({ state, dispatch, commit }, { id, data }) {
      try {
        const { data: schedule } = await startLoading(dispatch, 'loan:schedule:save', () => api.terminateLoan({ id, data }))
        commit('SET_LOAN_SCHEDULE', { schedule })
        dispatch('loadLoanSchedules')
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },

    async createPendingLoanSchedule ({ state, dispatch, commit }, { id }) {
      const { data: schedule } = await startLoading(dispatch, 'loan:schedule:save', () => api.putPendingSchedule({ id }))
      commit('SET_LOAN_SCHEDULE', { schedule })
      dispatch('loadLoanSchedules')
    },

    async acceptLoanSchedule ({ state, dispatch, commit }, { id, changeReasonComment, loanRelatedPayments }) {
      const { data: schedule } = await startLoading(dispatch, 'loan:schedule:accept:save', () => api.putLoanScheduleAccept({ id, changeReasonComment, loanRelatedPayments }))

      commit('SET_LOAN_SCHEDULE', { schedule })
      dispatch('loadLoanSchedules')
      dispatch('loadLoan', { id })
    },

    async removeLoanSchedule ({ state, dispatch, commit, getters }, { id }) {
      const { data: scheduleId } = await startLoading(dispatch, 'loan:schedule:remove', () => api.deleteLoanSchedule({ id }))

      commit('REMOVE_LOAN_SCHEDULE', { scheduleId })
      commit('SET_LOAN_SCHEDULE', { schedule: state.loanActiveSchedule[0] })
    },

    async createLoanScheduleInterest ({ state, dispatch, commit }, { interestData }) {
      const response = await startLoading(dispatch, 'loan:schedule:interest:save', () => api.postLoanScheduleInterest({ id: state.loan.id, interestData }))

      commit('SET_LOAN', { loan: response.data })
      dispatch('loadLoanSchedules')
      dispatch('loadLoanSchedule')
    },

    async updateLoanScheduleInterest ({ state, dispatch, commit }, { interestId, interestData }) {
      const response = await startLoading(dispatch, `loan:schedule:interest:${interestId}:save`, () => api.putLoanScheduleInterest({ id: state.loan.id, interestId, interestData }))

      commit('SET_LOAN', { loan: response.data })
      dispatch('loadLoanSchedules')
      dispatch('loadLoanSchedule')
    },

    async removeLoanScheduleInterest ({ state, dispatch, commit }, { interestId }) {
      const response = await startLoading(dispatch, `loan:schedule:interest:${interestId}:remove`, () => api.deleteLoanScheduleInterest({ id: state.loan.id, interestId }))

      commit('SET_LOAN', { loan: response.data })
      dispatch('loadLoanSchedules')
      dispatch('loadLoanSchedule')
    },
    async loadContractTemplate ({ commit, dispatch }, { templateId, contractId, languageId, scheduleId, invoiceDate }) {
      await startLoading(dispatch, `contract:template:${templateId}:fetch`, () => api.getLoanTemplate({ templateId, loanId: contractId, languageId, scheduleId, invoiceDate }))
    },
    async loadMessageTemplate ({ commit, dispatch }, { templateId, contractId, scheduleId }) {
      commit('SET_TEMPLATE', { template: null })

      if (!templateId) return

      const { data: template } = await startLoading(dispatch, 'contract:message:template:fetch', () => api.getLoanMessageTemplate({ templateId, loanId: contractId, scheduleId }))
      commit('SET_TEMPLATE', { template })
    },
    async loadLoanTransactions ({ commit, dispatch, state }, { filters } = {}) {
      commit('CLEAR_LOAN_TRANSACTIONS_PAGES')
      const { data: { content: loanTransactions, totalPages: loanTransactionsPages } } = await startLoading(dispatch, 'loan:transactions:fetch', () => api.getLoanTransactions({ id: state.loan.id, filters }))
      commit('SET_LOAN_TRANSACTIONS', { loanTransactions, loanTransactionsPages })
    },
    async sendContractMessage ({ commit, dispatch }, { id, data, isSendContract }) {
      await startLoading(dispatch, 'contract:message:save', () => isSendContract ? api.putContract({ id, data }) : api.putMessage({ id, data }))
    },
    async saveLoanProvision ({ commit, dispatch }, { loanId, provisionData }) {
      const { data: { financialDetail, accountStatement } } = await startLoading(dispatch, `contract:${loanId}:provision:save`, () => api.postLoanProvision({ loanId, provisionData }))

      commit('SET_LOAN_FINANCIAL', { financial: financialDetail })
      commit('ADD_LOAN_CONTRACT_TRANSACTION', { transaction: accountStatement })
    },
    async saveLoanCorrection ({ commit, dispatch }, { loanId, correctionData }) {
      const { data: { financialDetail, accountStatement } } = await startLoading(dispatch, `contract:${loanId}:correction:save`, () => api.putLoanCorrection({ loanId, correctionData }))

      commit('SET_LOAN_FINANCIAL', { financial: financialDetail })
      commit('ADD_LOAN_CONTRACT_TRANSACTION', { transaction: accountStatement })
    },
    async loadResources ({ state, commit, dispatch }, { filters }) {
      const { data: { content: resources, totalPages } } = await startLoading(dispatch, 'resources:fetch', () => {
        return api.getDocumentsByContract({ contractId: state.loan.id, filters })
      })
      commit('SET_LOAN_RESOURCES', { resources, totalPages })
    },
    async createLoanResource ({ state, commit, dispatch }, { resourceData, customerId, contractId = state.loan.id } = {}) {
      const response = await startLoading(dispatch, 'resource:save', () => {
        return api.postResource({
          contractId,
          resourceData,
          customerId
        })
      })
      commit('ADD_LOAN_RESOURCE', { resource: response.data })
    },
    async removeResource ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `resource:${id}:remove`, () => {
        return api.deleteResource({ id: id })
      })
      commit('REMOVE_LOAN_RESOURCE', { id })
    },
    async updateLoanOverdraftStatus ({ state, dispatch, commit }, { statusId }) {
      const response = await startLoading(dispatch, 'loan:status:save', () => api.putOverdraftStatus({ overdraftId: state.loan.id, statusId }))

      commit('SET_LOAN', { loan: response.data })
    },
    async updateLoanOverdraftTerms ({ dispatch, commit }, { overdraftId, termsData, termId }) {
      try {
        const { data: schedule } = await startLoading(dispatch, 'overdraft:term:save', () => api.putOverdraftTerm({ overdraftId, termsData, termId }))
        commit('UPDATE_LOAN_SCHEDULE', { schedule })
        commit('SET_LOAN_SCHEDULE', { schedule })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async loadLoanOverdraftTerms ({ dispatch, commit, state }, { overdraftId = state.loan.id } = {}) {
      const { data: terms } = await startLoading(dispatch, 'overdraft:terms:fetch', () => api.getOverdraftTerms({ id: overdraftId }))

      commit('SET_LOAN_SCHEDULES', { schedules: terms })
    },
    async loadLoanOverdraftTerm ({ dispatch, commit, state }, { overdraftId = state.loan.id, termId } = {}) {
      const { data: term } = await startLoading(dispatch, 'overdraft:terms:fetch', () => api.getOverdraftTerm({ id: overdraftId, termId }))

      commit('SET_LOAN_SCHEDULE', { schedule: term })
    },
    async createLoanOverdraftTermCopy ({ dispatch, commit }, { overdraftId, termId }) {
      const { data: schedule } = await startLoading(dispatch, 'overdraft:term:copy:save', () => api.postOverdraftTermCopy({ overdraftId, termId }))

      commit('UPDATE_LOAN_SCHEDULE', { schedule })
      commit('SET_LOAN_SCHEDULE', { schedule })
      return schedule
    },
    async deleteLoanOverdraftTerm ({ dispatch, commit, state }, { overdraftId, termId }) {
      try {
        await startLoading(dispatch, 'overdraft:term:delete', () => api.deleteOverdraftTerm({ overdraftId, termId }))
        commit('REMOVE_LOAN_SCHEDULE', { scheduleId: termId })

        dispatch('loadLoanOverdraftTerm', { termId: 'active' })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async activateLoanOverdraftTerm ({ dispatch, commit }, { overdraftId, termId, data }) {
      const { data: loan } = await startLoading(dispatch, 'overdraft:term:activate:save', () => api.putOverdraftTermActivate({ overdraftId, termId, data }))

      dispatch('loadLoanOverdraftTerm', { termId })
      commit('SET_LOAN', { loan })
    },
    async signLoanOverdraft ({ commit, dispatch }, { overdraftId, overdraftData }) {
      try {
        const { data: loan } = await startLoading(dispatch, `loan:${overdraftId}:save`, () => api.postOverdraftSign({ overdraftId, overdraftData }))
        commit('SET_LOAN', { loan })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async terminateLoanOverdraft ({ state, dispatch, commit }, { id, data }) {
      try {
        const { data: schedule } = await startLoading(dispatch, 'loan:overdraft:terminate', () => api.postOverdraftTerminate({ id, data }))
        commit('SET_LOAN_SCHEDULE', { schedule })
        commit('UPDATE_LOAN_SCHEDULE', { schedule })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async updateLoanSchedulePrincipals ({ dispatch, commit, state }, { principalsData }) {
      try {
        const { data: schedule } = await startLoading(dispatch, 'loan:schedule:principals:save', () => api.patchLoanSchedulePrincipals({ loanId: state.loan.id, principalsData }))
        commit('SET_LOAN_SCHEDULE', { schedule })
        dispatch('loadLoanSchedules')
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async loanLoanSystemData ({ dispatch, commit }) {
      const { data } = await startLoading(dispatch, 'loan:system-data:fetch', () => api.getLoanSystemData())

      commit('SET_LOAN_SYSTEM_DATA', { data })
    },
    async loadLoanRelations ({ dispatch, commit }, { loanId }) {
      const { data: relations } = await startLoading(dispatch, 'contract:relations:fetch', () => api.getContractRelations({ id: loanId }))

      commit('SET_LOAN_RELATIONS', { relations })
    },
    async createLoanRelation ({ dispatch, commit }, { loanId, relationData }) {
      const { data: relations } = await startLoading(dispatch, 'contract:relation:save', () => api.postContractRelation({ id: loanId, relationData }))

      commit('SET_LOAN_RELATIONS', { relations })
    },
    async updateLoanRelation ({ dispatch, commit }, { loanId, relationId, relationData }) {
      const { data: relation } = await startLoading(dispatch, 'contract:relation:save', () => api.putContractRelation({ id: loanId, relationId, relationData }))

      commit('UPDATE_LOAN_RELATION', { relation })
    },
    async deleteLoanRelation ({ dispatch, commit }, { loanId, relationId }) {
      await startLoading(dispatch, `contract:relation:${relationId}:delete`, () => api.deleteContractRelation({ id: loanId, relationId }))

      commit('REMOVE_LOAN_RELATION', { relationId })
    },
    async paybackLoan ({ dispatch, commit }, { id, data }) {
      const { data: schedule } = await startLoading(dispatch, 'loan:schedule:save', () => api.postLoanPayback({ id, data }))

      commit('SET_LOAN_SCHEDULE', { schedule })
      dispatch('loadLoanSchedules')
    },
    async updateResource ({ commit, dispatch }, { resourceId, resourceData }) {
      const { data: resource } = await startLoading(
        dispatch,
        `resource:${resourceId}:save`,
        () => api.putResource({ id: resourceId, resourceData })
      )

      commit('SET_LOAN_RESOURCE', { resource })
    },
    async loadComments ({ commit, dispatch, state }, { id = state.loan.id, filters } = {}) {
      commit('CLEAR_COMMENTS')

      const { data: { content: comments, totalPages } } = await startLoading(
        dispatch,
        'loans:comments:fetch',
        () => api.getCommentsByContract({ contractId: id, filters })
      )

      commit('SET_LOAN_COMMENTS', { comments, totalPages })
    },
    async createComment ({ state, commit, dispatch }, { commentData, commentableId = state.loan.id } = {}) {
      const { data: comment } = await startLoading(dispatch, 'loans:comment:save', () => {
        return api.postComment({ ...commentData, commentableId })
      })
      commit('ADD_LOAN_COMMENT', { comment })
    },
    async updateComment ({ commit, dispatch }, { commentId, commentData }) {
      const { data: comment } = await startLoading(dispatch, `loans:comment:${commentId}:save`, () => {
        return api.putComment({ id: commentId, data: commentData })
      })
      commit('UPDATE_LOAN_COMMENT', { comment })
    },
    async removeComment ({ commit, dispatch }, { id }) {
      await startLoading(dispatch, `loans:comment:${id}:remove`, () => {
        return api.deleteComment({ id })
      })
      commit('REMOVE_LOAN_COMMENT', { id })
    },
    async saveLoanScheduleChangeReason ({ commit, dispatch }, { loanId, changeReasonComment }) {
      const { data: schedule } = await startLoading(dispatch, 'loan:schedule:reason:save', () => api.putLoanScheduleReason({ loanId, changeReasonComment }))

      commit('SET_LOAN_SCHEDULE', { schedule })
      dispatch('loadLoanSchedules')
    },
    async updateLoanExtension ({ commit, dispatch }, { id, data }) {
      const { data: schedule } = await startLoading(dispatch, 'loan:extension:save', () => api.postLoanExtension({ id, data }))

      commit('SET_LOAN_SCHEDULE', { schedule })
      await dispatch('loadLoan', { id })
      dispatch('loadLoanSchedules')
    },
    async updateCommentAction ({ commit, dispatch }, { id, actionId, actionData }) {
      const { data: comment } = await startLoading(dispatch, 'loans:comment:action:save', () => api.putCommentAction({ id, actionData, actionId }))

      commit('UPDATE_LOAN_COMMENT', { comment })
    },
    async loadMessages ({ state, commit, dispatch }, filters) {
      const { data: { content: messages, totalPages } } = await startLoading(dispatch, 'customer:messages:fetch', () => {
        return api.getCustomerMessages({ customerId: state.loan.customerBorrower.id, filters })
      })
      commit('SET_LOAN_MESSAGES', { messages, totalPages })
    },
    async loadPaymentRelations ({ commit, dispatch, state }, { loanId = state.loan.id } = {}) {
      const { data: paymentRelations } = await startLoading(
        dispatch,
        'loan:paymentRelations:fetch',
        () => api.getPaymentRelations({ loanId })
      )

      commit('SET_LOAN_PAYMENT_RELATIONS', { paymentRelations })
    },
    async loadSystemCustomer ({ commit }, { id }) {
      if (!id) {
        commit('SET_SYSTEM_CUSTOMER', { customer: null })
        return
      }
      const { data: customer } = await api.getCustomer({ id })

      commit('SET_SYSTEM_CUSTOMER', { customer })
    },
    async createLoanPaymentRelation ({ commit, dispatch, state }, { loanId = state.loan.id, paymentRelationData } = {}) {
      const { data: paymentRelation } = await startLoading(
        dispatch,
        'paymentRelation:create:save',
        () => api.postPaymentRelation({ loanId, paymentRelationData })
      )

      commit('ADD_PAYMENT_RELATION', { paymentRelation })
    },
    async removeLoanPaymentRelation ({ commit, dispatch, state }, { loanId = state.loan.id, paymentRelationId } = {}) {
      await startLoading(
        dispatch,
        `paymentRelation:${paymentRelationId}:delete`,
        () => api.deletePaymentRelation({ loanId, paymentRelationId })
      )

      commit('REMOVE_PAYMENT_RELATION', { paymentRelationId })
    },
    async updateLoanPaymentRelation ({ commit, dispatch, state }, { loanId = state.loan.id, paymentRelationId, paymentRelationData } = {}) {
      const { data: paymentRelation } = await startLoading(
        dispatch,
        `paymentRelation:${paymentRelationId}:update:save`,
        () => api.putPaymentRelation({ loanId, paymentRelationId, paymentRelationData })
      )

      commit('UPDATE_PAYMENT_RELATION', { paymentRelation })
    },
    async loadCustomerHistory ({ state, commit, dispatch }, { customerId = state.loanCustomer?.id } = {}) {
      const response = await startLoading(dispatch, 'customer:history:fetch', () => {
        return api.getCustomerHistory({ customerId })
      })
      commit('SET_CUSTOMER_HISTORY', { history: response.data })
    },
    async createMessage ({ state, commit, dispatch }, { messageData, direction }) {
      const response = await startLoading(
        dispatch,
        'customer:message:save',
        () => api.postCustomerMessage({ customerId: state.loanCustomer?.id, data: messageData })
      )
      commit('ADD_LOAN_MESSAGE', { message: response.data, direction })
    },
    async loadLoanInterests ({ commit, dispatch, state }, { loanId = state.loan.id } = {}) {
      const { data: interests } = await startLoading(dispatch, 'loan:interests:fetch', () => api.getLoanExtraInterests({ loanId }))
      commit('SET_LOAN_INTERESTS', { interests })
    },
    async saveOverdraftPayout ({ commit, dispatch }, { overdraftId, payoutData }) {
      await startLoading(dispatch, 'overdraft:payout:save', () => api.portOverdraftPayout({ id: overdraftId, data: payoutData }))
      dispatch('loadLoan', { id: overdraftId })
    },
    async loadLoanTermDetails ({ commit, dispatch }, { contractId }) {
      const { data: termDetails } = await startLoading(dispatch, 'loan:term:details:fetch', () => api.getCreditContractTerms({ contractId }))

      commit('SET_LOAN_TERM_DETAILS', { termDetails })
    },
    async loadLoanCustomFields ({ dispatch, commit }, { loanId, productGroup }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'loan:custom-fields:fetch',
        () => api.getContractCustomFieldValues({ contractId: loanId, productGroup })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async saveLoanCustomFields ({ dispatch, commit }, { loanId, productGroup, customFieldData }) {
      const { data: customFields } = await startLoading(
        dispatch,
        'loan:custom-fields:save',
        () => api.postContractCustomFieldValues({ contractId: loanId, customFieldValues: customFieldData, productGroup })
      )
      commit('SET_CUSTOM_FIELDS', { customFields })
    },
    async updateOverdraftInvoices ({ dispatch, commit, state }, { invoiceData, overdraftInvoices }) {
      try {
        const { data: invoice } = await startLoading(dispatch, 'overdraft:schedule:deadline:save', () => api.putOverdraftInvoices({ id: state.loan.id, ...invoiceData }))
        commit('UPDATE_OVERDRAFT_INVOICE', { invoice, overdraftInvoices })
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    }
  },

  mutations: {
    SET_LOANS (state, { loans, loansPages, totalElements }) {
      state.loans = loans
      state.loansPages = loansPages
      state.loansTotal = totalElements
    },
    CLEAR_LOAN (state) {
      state.loan = null
      state.loanSchedule = null
      state.loanProduct = {}
      state.loanAssets = []
      state.loanMortgages = []
      state.loanCustomerAccounts = []
    },
    SET_LOAN (state, { loan }) {
      state.loan = omitBy(loan, isNil)
    },
    SET_SYSTEM_CUSTOMERS (state, { customers }) {
      state.systemCustomers = customers
    },
    SET_LOAN_PRODUCT (state, { product }) {
      state.loanProduct = product
    },
    SET_LOAN_CUSTOMER (state, { customer }) {
      state.loanCustomer = customer
    },
    SET_LOAN_CUSTOMER_ACCOUNTS (state, { accounts }) {
      state.loanCustomerAccounts = accounts
    },
    SET_LOAN_CUSTOMER_ACCOUNT (state, { account }) {
      state.loanCustomerAccounts = unionBy([account], state.loanCustomerAccounts, 'id')
    },
    SET_LOAN_ASSETS (state, { assets }) {
      state.loanAssets = orderBy(assets, ['id', 'createdAt'], 'desc')
    },
    UPDATE_LOAN_ASSET (state, { asset }) {
      state.loanAssets = unionBy([asset], state.loanAssets, 'id')
    },
    REMOVE_LOAN_ASSET (state, { id }) {
      state.loanAssets = state.loanAssets.filter(asset => asset.id !== id)
    },
    SET_LOAN_MORTGAGES (state, { mortgages }) {
      state.loanMortgages = orderBy(mortgages, ['id', 'createdAt'], 'desc')
    },
    UPDATE_LOAN_MORTGAGE (state, { mortgage }) {
      state.loanMortgages = unionBy([mortgage], state.loanMortgages, 'id')
    },
    REMOVE_LOAN_MORTGAGE (state, { id }) {
      state.loanMortgages = state.loanMortgages.filter(mortgage => mortgage.id !== id)
    },
    SET_LOAN_TRANSACTIONS (state, { loanTransactions, loanTransactionsPages }) {
      state.loanTransactions = loanTransactions
      state.loanTransactionsPages = loanTransactionsPages
    },
    CLEAR_LOAN_TRANSACTIONS_PAGES (state) {
      state.loanTransactionsPages = 0
    },
    SET_TEMPLATE (state, { template }) {
      state.messageTemplate = template
    },
    SET_LOAN_RESOURCES (state, { resources, totalPages }) {
      state.loanResources = resources
      state.loanResourcesPages = totalPages
    },
    ADD_LOAN_RESOURCE (state, { resource }) {
      state.loanResources.push(resource)
    },
    REMOVE_LOAN_RESOURCE (state, { id }) {
      state.loanResources = state.loanResources.filter(resource => resource.id !== id)
    },
    SET_LOAN_SYSTEM_DATA (state, { data }) {
      state.systemData = data
    },
    SET_LOAN_RELATIONS (state, { relations }) {
      state.loanRelations = relations
    },
    UPDATE_LOAN_RELATION (state, { relation }) {
      state.loanRelations = unionBy([relation], state.loanRelations, 'relationId')
    },
    REMOVE_LOAN_RELATION (state, { relationId }) {
      state.loanRelations = state.loanRelations.filter(relation => relation.relationId !== relationId)
    },
    SET_LOAN_RESOURCE (state, { resource }) {
      state.loanResources = unionBy([resource], state.loanResources, 'id')
    },
    SET_LOAN_COMMENTS (state, { comments, totalPages }) {
      state.loanComments = comments
      state.commentsPages = totalPages
    },
    ADD_LOAN_COMMENT (state, { comment }) {
      state.loanComments.push(comment)
    },
    UPDATE_LOAN_COMMENT (state, { comment }) {
      state.loanComments = unionBy([comment], state.loanComments, 'id')
    },
    REMOVE_LOAN_COMMENT (state, { id }) {
      state.loanComments = state.loanComments.filter(comment => comment.id !== id)
    },
    CLEAR_COMMENTS (state) {
      state.loanComments = []
      state.commentsPages = 0
    },
    SET_LOAN_MESSAGES (state, { messages, totalPages }) {
      state.loanMessages = messages
      state.loanMessagesPages = totalPages
    },
    ADD_LOAN_MESSAGE (state, { message, direction }) {
      if (message.direction !== direction) {
        return
      }
      state.loanMessages.push(message)
    },
    SET_LOAN_PAYMENT_RELATIONS (state, { paymentRelations }) {
      state.loanPaymentRelations = paymentRelations
    },
    ADD_PAYMENT_RELATION (state, { paymentRelation }) {
      state.loanPaymentRelations.push(paymentRelation)
    },
    REMOVE_PAYMENT_RELATION (state, { paymentRelationId }) {
      state.loanPaymentRelations = state.loanPaymentRelations.filter(({ relationId }) => paymentRelationId !== relationId)
    },
    UPDATE_PAYMENT_RELATION (state, { paymentRelation }) {
      state.loanPaymentRelations = orderBy(unionBy([paymentRelation], state.loanPaymentRelations, 'relationId'), ['relationId'], ['asc'])
    },
    SET_SYSTEM_CUSTOMER (state, { customer }) {
      state.systemCustomer = customer
    },
    SET_LOAN_SCHEDULE (state, { schedule }) {
      state.loanSchedule = schedule
    },
    SET_LOAN_SCHEDULES (state, { schedules, schedulePages, totalElements }) {
      state.loanSchedules = schedules
      state.schedulePages = schedulePages
      state.schedulesTotal = totalElements
    },
    SET_LOAN_ACTIVE_SCHEDULE (state, { schedules }) {
      state.loanActiveSchedule = schedules
    },
    REMOVE_LOAN_SCHEDULE (state, { scheduleId }) {
      state.loanSchedules = state.loanSchedules.filter(({ id }) => id !== scheduleId)
    },
    ADD_LOAN_SCHEDULE (state, { schedule }) {
      state.loanSchedules.push(schedule)
    },
    UPDATE_LOAN_SCHEDULE (state, { schedule }) {
      state.loanSchedules = unionBy([schedule], state.loanSchedules, 'id')
    },
    SET_CUSTOMER_HISTORY (state, { history }) {
      state.loanCustomerHistory = {
        ...state.loanCustomerHistory,
        contracts: orderBy([...(history.loans || []), ...(history.deposits || []), ...(history.factorings ?? [])], ['createdAt', 'id'], ['desc', 'desc']),
        creditApplications: orderBy(history.creditApplications, ['createdAt', 'id'], ['desc', 'desc'])
      }
    },
    SET_LOAN_FINANCIAL (state, { financial }) {
      state.loanFinancial = financial
    },
    SET_LOAN_IFRS (state, { ifrs }) {
      state.loanIfrs = ifrs
    },
    SET_LOAN_INTERESTS (state, { interests }) {
      state.loanInterests = interests
    },
    SET_LOAN_TERM_DETAILS (state, { termDetails }) {
      state.loanTermDetails = termDetails
    },
    SET_CUSTOMER_RELATIONS (state, { relations }) {
      state.loanCustomerRelations = relations
    },
    ADD_LOAN_CONTRACT_TRANSACTION (state, { transaction }) {
      state.loanTransactions = [transaction, ...state.loanTransactions]
    },
    SET_CUSTOM_FIELDS (state, { customFields }) {
      state.customFieldsValues = customFields
    },
    UPDATE_OVERDRAFT_INVOICE (state, { invoice, overdraftInvoices }) {
      if (overdraftInvoices) {
        state.loanSchedule.overdraftInvoices = overdraftInvoices
      } else {
        state.loanSchedule.overdraftInvoices = unionBy([invoice], state.loanSchedule.overdraftInvoices, 'fee')
      }
    }
  }
}
