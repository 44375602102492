export default {
  value: null,
  valueDate: '',
  insuranceMandatory: false,
  insuranceCompany: null,
  insuredUntil: '',
  assetTypeId: '',
  appraisalReport: false,
  appraisalPartnerName: null,
  appraisalValue: null,
  assetInfo: '',
  comment: '',
  allocatedValue: null
}
