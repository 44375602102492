import { connection } from './connection'

export default {
  putAsset ({ id, assetData }) {
    return connection.put(`bo/collateral/assets/${id}`, assetData)
  },

  deleteAsset ({ id }) {
    return connection.delete(`bo/collateral/assets/${id}`)
  },

  getAssetsMessages ({ id, lang }) {
    return connection.get(`bo/collateral/assets/${id}/messages?lang=${lang}`)
  },

  putMortgage ({ id, mortgageData }) {
    return connection.put(`bo/collateral/mortgages/${id}`, mortgageData)
  },

  deleteMortgage ({ id }) {
    return connection.delete(`bo/collateral/mortgages/${id}`)
  },

  postMortgageAsset ({ id, assetData }) {
    return connection.post(`bo/collateral/mortgages/${id}/asset`, assetData)
  },

  deleteMortgageAsset ({ relationId }) {
    return connection.delete(`bo/collateral/mortgages/assets/${relationId}`)
  }
}
